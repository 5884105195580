<template>
  <div class="form-wrapper" :class="formState">
    <div class="inner-wrapper">
      <div class="form">
        <form action="" @focusout="change" @input="change" @submit="submit" novalidate>
          <div class="form-group">
            <div class="input-field-wrapper" :class="{ invalid: validation.email.errorMessage }">
              <label for="email"><img src="../../assets/mage_email.png" alt="email" class="img-fluid d-none d-md-block" style="width: 45%; padding-top: 24px;"></label>
              <div class="input-field">
                <input type="email" name="email" id="email" data-rules='["required","email"]' v-model="email" placeholder="Email" />
              </div>
            </div>
            <p class="error-message" v-if="validation.email.errorMessage">
              {{ validation.email.errorMessage }}
            </p>
          </div>

          <div class="form-group">
            <div class="input-field-wrapper password-input-field-wrapper" :class="{ invalid: validation.email.errorMessage }">
              <label for="password"><img src="../../assets/light_password.png" alt="password" class="img-fluid d-none d-md-block" style="width: 45%; padding-top: 24px;"></label>
              <div class="input-field-inner-wrapper">
                <div class="input-field">
                  <input type="password" name="password" id="password" data-rules='["required"]' v-model="password" placeholder="Password" />
                </div>
                <!-- <router-link to="/reset-password" class="cant-remember">Can't Remember</router-link> -->
              </div>
            </div>

            <p class="error-message" v-if="validation.password.errorMessage">
              {{ validation.password.errorMessage }}
            </p>
          </div>

          <div class="form-error" v-if="error">
            <div class="error">{{ error }}</div>
          </div>

          <div class="forgot-password" style="float: right;">
            <!-- <a href="#" class="mb-4 text-dark">Forgot Password?</a> -->
            <router-link to="/reset-password" class="mb-4 text-white" style="font-size: 13px;">Forgot Password?</router-link>
          </div>
          <br>
          <div class="d-flex justify-content-between mb-4 mt-4" style="padding: 0 20px;">
            <button type="submit" class="btn btn-dark login-button" style="font-size: 16px !important;">Login</button>
            <router-link to="/register" class="btn btn-outline-dark register-login" style="font-size: 16px !important;">Sign Up</router-link>
          </div>
        </form>
        <googleAuth/>
      </div>
      <div class="loading-overlay">
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import formValidation from '@/mixins/formValidation';
import googleAuth from '@/components/auth/googleAuth.vue';

export default {
  name: 'LoginForm',
  mixins: [formValidation],
  components: { googleAuth },
  data() {
    return {
      validation: {
        inputElements: [],
        email: {},
        password: {},
      },
      email: '',
      password: '',
    };
  },
  mounted() {
    /* if (typeof this.$route.query.redirect != 'undefined') {
      this.$store.dispatch('setAfterLoginRedirectPath', this.$route.query.redirect);
    } */
    this.validation.inputElements = [...this.$el.querySelectorAll('input[data-rules]')];
    this.initValidation();
  },
  methods: {
    send() {
      this.error = null;
      this.$store
        .dispatch('login', {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.formState = null;
          let redirectPath = {
            'path' : '/get-a-quote'
            };

          if (this.$store.state.afterLoginRedirect != null) {
            redirectPath = this.$store.state.afterLoginRedirect;
          }

          this.$router.push({ path: redirectPath.path, query: redirectPath.query });
        })
        .catch((error) => {
            if(typeof error === 'object')
            {
                this.error = 'Something went wrong, Please try agian.';
            }
            else {
                this.error = error;
            }
            this.formState = null;
        });
    },
  },
};
</script>

<style lang="scss">

.form-wrapper {
  position: relative;
}

.auth-form .form-group {
  margin-bottom: 10px !important;
}

/* Apply the background image and overlay */
.form-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background: url('../../assets/auth-bg.png') no-repeat center center/cover; /* Background image */
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: -1; /* Ensure it’s behind the content */
}

/* Optional: Add a color overlay */
.form-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.3); /* Black overlay with 30% opacity */
  z-index: -1; /* Ensure it’s behind the content */
}

.auth-form-view .auth-form .form-wrapper {
  background-color: #e5e5e500 !important;
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.auth-form-view .auth-form .form-wrapper .inner-wrapper {
  background-color: #ffffff00 !important;
  padding: 0px 35px !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
}

button.btn.btn-dark {
  background-color: #474747;
  width: 47%;
  box-shadow: 0 2px 30px 0 rgb(231 231 231 / 61%) !important;
  font-weight: 400;
}

button.btn.btn-dark.login-button {
  width: 46% !important;
}

button.btn.btn-dark:hover {
  background-color: transparent;
  color: #ffffff;
  width: 47%;
  box-shadow: 0 2px 30px 0 rgb(231 231 231 / 61%) !important;
  font-weight: 400;
  border: 1px solid;
}

a.btn.btn-outline-dark {
  background-image: linear-gradient(to right, #1985A1 0%, #024455 100%);
  height: 57px;
  border: 0px none;
  color: #fff;
  font-size: 17px;
  border: 1px solid;
  width: 47%;
  line-height: 40px;
}
a.btn.btn-outline-dark:hover {
  background-color: #474747;
  width: 47%;
  box-shadow: 0 2px 30px 0 rgb(231 231 231 / 61%) !important;
  font-weight: 400;
  color: #fff;
}

a.btn.btn-outline-dark.register-login{
  height: 40px !important;
  border: none;
}

.auth-form .input-field-wrapper {
  height: 50px !important;
  border-radius: 5px;
  background-color: #fbfbfb !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  box-shadow: 0 2px 5px 0 rgb(203 203 203 / 61%) !important;
}

.auth-form .input-field-wrapper label:after {
  display: none !important;
}

.auth-form .input-field-wrapper input[type=text], .auth-form .input-field-wrapper input[type=email], .auth-form .input-field-wrapper input[type=password], .auth-form .input-field-wrapper input[type=tel] {
  font-size: 13px !important;
}

.auth-form .input-field-wrapper label {
  width: 60px !important;
}

@media (max-width: 590px) {
  .auth-form .input-field-wrapper label {
      height: 0px !important;
  }
}
</style>