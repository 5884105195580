<template>
  <div class="auth-form-view">
    <div class="container">
      <div class="row align-items-center justify-content-center"> <!-- Center align vertically and horizontally -->
        <!-- Left Image Column -->
        <!-- <div class="col-md-6"> -->
          <!-- <img src="../assets/auth.png" alt="Auth Image" class="img-fluid" /> -->
        <!-- </div> -->

        <!-- Right Form Column -->
        <div class="col-md-12">
          <div class="text-center">
            <img src="../assets/logo2.png" alt="Logo" class="img-fluid" style="width: 12%;">
          </div>
          <div class="auth-form login-form text-center" style="margin-top: 20px;"> <!-- Center text -->
            <h1 class="mb-3" style="font-size: 30px; color:#fff;">Welcome!</h1>
            <p class="mb-4" style="font-size: 20px; color:#fff;">Sign in to your Account</p>

            <LoginForm />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/auth/LoginForm.vue";

export default {
  name: "LoginView",
  data() {
    return {
      success: '',
      error: '',
    };
  },
  components: {
    LoginForm,
  },
  mounted() {
    this.success = this.$route.query['success'];
    this.error = this.$route.query['error'];
  },
  methods : {
    isGettingQuote() {
      return this.$store.state.afterLoginRedirect != null && this.$store.state.afterLoginRedirect.path == '/quote';
    }
  }
};
</script>

<style lang="scss">
@import "../scss/partials/variables.scss";
@import "../scss/partials/auth-form-view.scss";
</style>

<style lang="scss">

.auth-form {
  max-width: 580px !important;
  margin-bottom: 50px !important;
}
.auth-form-view .login-form {
  background: rgb(248 249 250 / 12%) !important;
  border-radius: 89px !important;
  backdrop-filter: blur(10px);
  padding: 35px 0px;
}

.auth-form-view {
  background: linear-gradient(rgb(0 0 0 / 10%), rgb(0 0 0 / 9%)), url('../assets/auth-bg.png') no-repeat center center;

background-size: cover;
min-height: 100vh;
  .container {
    max-width: 1200px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .login-form {
    background: #f8f9fa00; // Light background
    border-radius: 8px;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .btn-outline-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .auth-form-view .auth-form .form-wrapper .inner-wrapper {
    background-color: #fff0 !important;
    padding: 30px 35px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
  }
  .auth-form-view .auth-form .form-wrapper {
    background-color: rgb(0, 0, 0) !important;
    padding: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
}
</style>